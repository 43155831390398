function navigationOverlayToggle() {
  const open = document.querySelector(".navigation__menu-button-open");
  const close = document.querySelector(".navigation__menu-button-close");
  const navigationWrapper = document.querySelector(".navigation");

  if (!open && !navigationWrapper) {
    return;
  }

  open.addEventListener("click", () => {
    console.log("hi");
    navigationWrapper.classList.add("navigation--open");
  });

  close.addEventListener("click", () => {
    console.log("bye");
    navigationWrapper.classList.remove("navigation--open");
  });
}

export default navigationOverlayToggle;
